import dynamic from 'next/dynamic';
import React, { memo, useCallback, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { I_Layout, I_Translations, I_User } from '@containers/App/types';
import { useValidatePermission } from '@utils/hooks/useValidatePermission';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { actions } from '@containers/App/slice';
import { useMediaQuery } from 'react-responsive';

const LinkInOutApp = dynamic(() => import('../LinkInOutApp'));
const SelectProvince = dynamic(() =>
  import('./SelectProvince').then(module => module.SelectProvince),
);

interface Props {
  layout: I_Layout;
  infoUser: I_User;
  isLogged: boolean;
  tourFlycam: string;
  trans: I_Translations;
  provinceSelected: string;
  onShowDialogFaq: () => void;
  onShowDialogInstruct: () => void;
  onShowDialogContribute: () => void;
}

const Header = memo(
  ({
    trans,
    layout,
    infoUser,
    isLogged,
    tourFlycam,
    onShowDialogFaq,
    onShowDialogInstruct,
    provinceSelected,
    onShowDialogContribute,
  }: Props) => {
    const router = useRouter();
    const dispatch = useDispatch();

    const [isShowMenu, setIsShowMenu] = useState(false);

    const isMobi = useMediaQuery({
      query: '(max-width: 640px)',
    });

    const { onShowDialogLogin } = useValidatePermission();

    const toggleMenu = () => {
      setIsShowMenu(!isShowMenu);
    };

    const onLogout = () => dispatch(actions.logout());

    const onChangeProvinceSelected = useCallback(
      ({ province, tourSlug }) => {
        dispatch(actions.setProvinceSelected(province));
        tourSlug && dispatch(actions.setTourFlycam(tourSlug));
        if (router.pathname.includes('/[province]/discover'))
          router.push({
            pathname: '/[province]/discover',
            query: { province },
          });
        else if (router.pathname.includes('/[province]/map'))
          router.push({
            pathname: '/[province]/map',
            query: { province },
          });
      },
      [dispatch, router],
    );

    return !isMobi ? (
      <header className="--header">
        <div className="header-left">
          {provinceSelected && (
            <LinkInOutApp href={`/${provinceSelected}/discover/${tourFlycam}`}>
              <div className="--logo">
                <img
                  src={
                    layout?.header?.logo?.thumbnail_640_jpg ||
                    layout?.header?.logo?.thumbnail_720_jpg ||
                    layout?.header?.logo?.default
                  }
                  alt=""
                />
              </div>
            </LinkInOutApp>
          )}
          <div className="dropdown --address">
            <SelectProvince
              provinceSelected={provinceSelected}
              dropdownItems={layout?.header?.jsonProvinces}
              onChangeProvinceSelected={onChangeProvinceSelected}
            />
          </div>
          {provinceSelected && (
            <div className="--listTab d-flex">
              <LinkInOutApp
                href={`/${provinceSelected}/discover/${tourFlycam}`}
                className={`--item link ${
                  router.pathname.includes('/[province]/discover')
                    ? 'active'
                    : ''
                }`}
              >
                <>
                  <i className="fa-solid fa-globe"></i>
                  <span> {trans ? trans['fe.header.discover'] : ''}</span>
                </>
              </LinkInOutApp>
              <LinkInOutApp
                href={`/${provinceSelected}/map`}
                className={`--item link ${
                  router.pathname.includes('/[province]/map') ? 'active' : ''
                }`}
              >
                <>
                  <i className="fa-solid fa-location-dot"></i>
                  <span>{trans ? trans['fe.header.map'] : ''}</span>
                </>
              </LinkInOutApp>
            </div>
          )}
        </div>
        <div className="header-right">
          <div className="--menu">
            <LinkInOutApp href="/about" className="--item link">
              <>{trans ? trans['fe.header.introduce'] : ''}</>
            </LinkInOutApp>
            <LinkInOutApp href="/sponsor" className="--item link">
              <> {trans ? trans['fe.header.sponsor'] : ''}</>
            </LinkInOutApp>

            <div className="--item link" onClick={onShowDialogFaq}>
              {trans ? trans['fe.header.faq'] : ''}
            </div>
            <div className="--item link" onClick={onShowDialogInstruct}>
              Hướng dẫn
            </div>
            <div
              className="--item link --btn-submitPano"
              onClick={onShowDialogContribute}
            >
              <i className="fa-solid fa-plus"></i>
              {trans ? trans['fe.header.contribute'] : ''}
            </div>
          </div>
          {/* <div className="--language">
            <select name="" id="">
              <option value="">VI</option>
              <option value="">EN</option>
            </select>
          </div> */}
          {!isLogged ? (
            <div className="btn btn-login" onClick={onShowDialogLogin}>
              Đăng nhập
            </div>
          ) : (
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <div className="--user">
                  <div className="--avatar">
                    <img
                      src={
                        infoUser?.avatar?.thumbnail_640_jpg ||
                        infoUser?.avatar?.thumbnail_720_jpg ||
                        infoUser?.avatar?.default
                      }
                      alt=""
                    />
                  </div>
                  <div className="--name">{infoUser?.name}</div>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <div className="--logout" onClick={onLogout}>
                  Đăng xuất
                </div>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </header>
    ) : (
      <header className="--header">
        <div className="header-top">
          {provinceSelected && (
            <LinkInOutApp href={`/${provinceSelected}/discover/${tourFlycam}`}>
              <div className="--logo">
                <img
                  src={
                    layout?.header?.logo?.thumbnail_640_jpg ||
                    layout?.header?.logo?.thumbnail_720_jpg ||
                    layout?.header?.logo?.default
                  }
                  alt=""
                />
              </div>
            </LinkInOutApp>
          )}
          {!isLogged ? (
            <div className="btn btn-login" onClick={onShowDialogLogin}>
              Đăng nhập
            </div>
          ) : (
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <div className="--user">
                  <div className="--avatar">
                    <img
                      src={
                        infoUser?.avatar?.thumbnail_640_jpg ||
                        infoUser?.avatar?.thumbnail_720_jpg ||
                        infoUser?.avatar?.default
                      }
                      alt=""
                    />
                  </div>
                  <div className="--name">{infoUser?.name}</div>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <div className="--logout" onClick={onLogout}>
                  Đăng xuất
                </div>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
        <div className="header-bot">
          {provinceSelected && (
            <>
              <LinkInOutApp
                href={`/${provinceSelected}/discover/${tourFlycam}`}
                className={`--item link ${
                  router.pathname.includes('/[province]/discover')
                    ? 'active'
                    : ''
                }`}
              >
                <>
                  <i className="fa-solid fa-globe"></i>
                  <span> {trans ? trans['fe.header.discover'] : ''}</span>
                </>
              </LinkInOutApp>
              <LinkInOutApp
                href={`/${provinceSelected}/map`}
                className={`--item link ${
                  router.pathname.includes('/[province]/map') ? 'active' : ''
                }`}
              >
                <>
                  <i className="fa-solid fa-location-dot"></i>
                  <span>{trans ? trans['fe.header.map'] : ''}</span>
                </>
              </LinkInOutApp>
            </>
          )}
          <div className="--item link" onClick={() => toggleMenu()}>
            {!isShowMenu ? (
              <i className="fa-solid fa-bars"></i>
            ) : (
              <i className="fa-solid fa-xmark"></i>
            )}
            <span>Menu</span>
          </div>
        </div>
        <div className={`--menu ${isShowMenu === true ? 'active' : ''}`}>
          <LinkInOutApp href="/about" className="--item link">
            <span onClick={() => toggleMenu()}>
              {trans ? trans['fe.header.introduce'] : ''}
            </span>
          </LinkInOutApp>
          <LinkInOutApp href="/sponsor" className="--item link">
            <span onClick={() => toggleMenu()}>
              {trans ? trans['fe.header.sponsor'] : ''}
            </span>
          </LinkInOutApp>
          <div className="--item link" onClick={onShowDialogFaq}>
            {trans ? trans['fe.header.faq'] : ''}
          </div>
          <div className="--item link" onClick={onShowDialogInstruct}>
            Hướng dẫn
          </div>
          <div
            className="--item link --btn-submitPano"
            onClick={onShowDialogContribute}
          >
            <i className="fa-solid fa-plus"></i>
            {trans ? trans['fe.header.contribute'] : ''}
          </div>
        </div>
      </header>
    );
  },
);

export default Header;
